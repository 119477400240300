import React, { useEffect, useState } from 'react';
import {
    StyledCreditLimitReviewReportPage,
    StyledDateContainer,
    StyledFilterBar,
    StyledHeader,
    StyledHeaderText,
    StyledRefreshContainer
} from './CreditLimitReviewReportPage.style';
import { DateInput } from 'components/general/DateInput';
import { addMonths } from 'utils/date.utils';
import { IconButton } from 'components/general/Button';
import { IDropdownOption, Icon } from '@fluentui/react';
import { isNullOrEmpty, isNullOrUndefined } from 'utils/validation.utils';
import { CreditLimitRangeEnum } from '../enums/CreditLimitRange.enum';
import TextInput from 'components/general/TextInput';
import { theme } from 'themes/aplant.theme';
import { tryParseInt } from 'utils/String.utils';
import { formatNumber, renderNullableDate } from 'utils/format.utils';
import Select from 'components/general/Select';
import { mapToDropdownOptions } from 'utils/mapping.utils';
import {
    GetCreditLimitReportData,
    GetCreditLimitReportDataVariables,
    GetCreditLimitReportData_Response
} from '../queries/CreditLimitReviewReport.queries.types';
import { GetCreditLimitReviewData } from '../queries/CreditLimitReviewReport.queries';
import { useApolloClient } from '@apollo/react-hooks';
import VirtualTable, { IVirtualTableColumn } from 'components/general/NewTable/Table/components/VirtualTable';
import { StyledSection } from 'sections/customer-accounts/configuration/shared/styles/configuration.styles';
import GraphQlErrorBoundary from 'components/shared/ErrorBoundary';
import GraphQlLoading from 'components/shared/Loading/';
import { useToasts } from 'react-toast-notifications';
import ApolloErrorToastMessage from 'utils/errors/components/ApolloErrorToastMessage';
import { CreditLimitRangeStatusEnum } from '../enums/CreditLimitRangeStatus.enum';
import { mapToEnumOptions } from 'sections/customer-accounts/shared/mappers/enum.mapper';
import CreditLimitReviewDataDownload from 'sections/customer-accounts/report/components/CreditLimitReviewDataDownload';
import { addOrRemove } from 'utils/array.utils';
import { ApolloError } from 'apollo-client';
import { ActionError } from 'utils/errors/ActionResponse';
import { useDebounce } from 'hooks/useDebounce';

type FilterState = {
    creditLimit: number;
    creditLimitRange: number;
    statusIds: number[];
    dateFrom: ISODateString;
    dateTo: ISODateString;
};

const CreditLimitReviewReportPage = () => {
    const firstDay = () => {
        let date_today = new Date();

        return new Date(date_today.getFullYear(), date_today.getMonth(), 1).toISOString();
    };

    const lastDay = () => {
        let date_today = new Date();
        return new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0).toISOString();
    };

    const [filters, setFilters] = useState<FilterState>({
        creditLimit: 20001,
        creditLimitRange: 2,
        statusIds: [8, 9, 10],
        dateFrom: firstDay(),
        dateTo: lastDay()
    });

    const [persistedFilters, setPersistedFilters] = useState<FilterState>({
        creditLimit: 20001,
        creditLimitRange: 2,
        statusIds: [8, 9, 10],
        dateFrom: firstDay(),
        dateTo: lastDay()
    });
    const [isCreditLimitFocused, setIsCreditLimitFocused] = useState<boolean>(false);
    const [data, setData] = useState<GetCreditLimitReportData[] | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);
    const client = useApolloClient();
    const { addToast } = useToasts();
    const [error, setError] = useState<ActionError<unknown> | null>(null);

    const columns: IVirtualTableColumn<GetCreditLimitReportData>[] = [
        {
            key: 'column0',
            name: 'Application Id',
            fieldName: 'CustomerId',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
            sortAscendingAriaLabel: 'CustomerId',
            onRender: (item: GetCreditLimitReportData) => item.customerId
        },
        {
            key: 'column1',
            name: 'Company Id',
            fieldName: 'CompanyId',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
            sortAscendingAriaLabel: 'CompanyId',
            onRender: (item: GetCreditLimitReportData) => item.companyId
        },
        {
            key: 'column2',
            name: 'Customer Number',
            fieldName: 'MCSReference',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => item.mCSReference
        },
        {
            key: 'column3',
            name: 'Customer Name',
            fieldName: 'ApplicantName',
            minWidth: 250,
            maxWidth: 400,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => item.applicantName
        },
        {
            key: 'column4',
            name: 'Credit Limit',
            fieldName: 'CreditLimit',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => item.creditLimit
        },
        {
            key: 'column5',
            name: 'Credit Controller',
            fieldName: 'CreditControllerName',
            minWidth: 250,
            maxWidth: 400,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => item.creditControllerName
        },
        {
            key: 'column6',
            name: 'Next Review Date',
            fieldName: 'AccountReviewDate',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            data: 'date',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => renderNullableDate(item.accountReviewDate)
        },
        {
            key: 'column7',
            name: 'Status',
            fieldName: 'StatusDescription',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: GetCreditLimitReportData) => item.statusDescription
        }
    ];

    useEffect(() => {
        getCreditLimitReport();
    }, [persistedFilters]);

    const getCreditLimitReport = () => {
        setLoading(true);
        setError(null);
        client
            .query<GetCreditLimitReportData_Response, GetCreditLimitReportDataVariables>({
                query: GetCreditLimitReviewData,
                variables: {
                    filter: {
                        creditLimit: persistedFilters.creditLimit,
                        creditLimitRange: CreditLimitRangeEnum[persistedFilters.creditLimitRange].toUpperCase(),
                        statuses: persistedFilters.statusIds.map(v => CreditLimitRangeStatusEnum[v].toUpperCase()),
                        reviewStartDate: persistedFilters.dateFrom,
                        reviewEndDate: persistedFilters.dateTo
                    }
                },
                fetchPolicy: 'network-only'
            })
            .then(res => {
                if (res.data) {
                    setData(res.data.creditLimitReviewData);
                }
            })
            .catch(err => {
                addToast(<ApolloErrorToastMessage error={err} baseMessage='Issue retrieving data' />, {
                    appearance: 'error'
                });
                setError({
                    message: err.message
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDateChange = (name: string) => (date: Date | null | undefined) => {
        if (isNullOrUndefined(date)) return;

        setFilters(currentFilters => {
            return {
                ...currentFilters,
                [name]: date.toISOString()
            };
        });

        setPersistedFilters(currentFilters => {
            return {
                ...currentFilters,
                [name]: date.toISOString()
            };
        });
    };

    const handleCreditLimitUpdate = (newValue?: string): void => {
        if (isNullOrUndefined(newValue)) return;

        const creditLimit = isNullOrEmpty(newValue) ? 0 : tryParseInt(newValue.toString().slice(0, 9)) ?? 0;
        setFilters(currentFilters => {
            return {
                ...currentFilters,
                creditLimit: creditLimit
            };
        });

        setPersistedFilters(currentFilters => {
            return {
                ...currentFilters,
                creditLimit: creditLimit
            };
        });
    };

    const [creditLimitFilterValue, onCreditLimitChangeDebounce] = useDebounce<number>(
        (newValue?: string) => {
            handleCreditLimitUpdate(newValue);
        },
        800,
        persistedFilters.creditLimit
    );

    const handleFilterSelectChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        name?: string
    ): void => {
        if (isNullOrEmpty(name)) return;

        let selectedValue = option?.key;

        setFilters(currentFilters => {
            return {
                ...currentFilters,
                [name]: selectedValue
            };
        });

        setPersistedFilters(currentFilters => {
            return {
                ...currentFilters,
                [name]: selectedValue
            };
        });
    };

    const handleFilterMultiSelectChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        name?: string
    ): void => {
        if (isNullOrEmpty(name)) return;

        setFilters(currentFilters => {
            const filterValue = currentFilters[name as keyof FilterState];
            let newValue = filterValue;

            if (Array.isArray(filterValue)) newValue = addOrRemove(filterValue, option?.key, x => x) as number[];

            return {
                ...currentFilters,
                [name]: newValue
            };
        });

        setPersistedFilters(currentFilters => {
            const filterValue = currentFilters[name as keyof FilterState];
            let newValue = filterValue;

            if (Array.isArray(filterValue)) newValue = addOrRemove(filterValue, option?.key, x => x) as number[];

            return {
                ...currentFilters,
                [name]: newValue
            };
        });
    };

    const handleRefreshClick = async (): Promise<void> => {
        getCreditLimitReport();
    };

    return (
        <StyledCreditLimitReviewReportPage>
            <StyledHeader>
                <StyledHeaderText>Credit Limit Review Report</StyledHeaderText>
            </StyledHeader>
            <StyledFilterBar>
                <TextInput
                    style={{ color: theme.palette.info.main, fontWeight: 'bold', minWidth: '225px', maxWidth: '300px' }}
                    prefix='£'
                    onFocus={() => {
                        setIsCreditLimitFocused(true);
                    }}
                    onBlur={() => setIsCreditLimitFocused(false)}
                    onChange={(e, v) => onCreditLimitChangeDebounce(tryParseInt(v) ?? 0)}
                    value={
                        !isCreditLimitFocused ? formatNumber(creditLimitFilterValue) : creditLimitFilterValue.toString()
                    }
                />
                <Select
                    style={{ minWidth: '225px' }}
                    placeholder='Credit Limit Range'
                    selectedKey={filters.creditLimitRange}
                    options={mapToDropdownOptions(
                        mapToEnumOptions(CreditLimitRangeEnum),
                        x => x.key,
                        x => x.value
                    )}
                    onChange={handleFilterSelectChange}
                    name='creditLimitRange'
                    title='Credit Limit Range'
                />
                <Select
                    style={{ minWidth: '225px' }}
                    placeholder='Customer Status'
                    multiSelect
                    selectedKeys={filters.statusIds}
                    options={mapToDropdownOptions(
                        mapToEnumOptions(CreditLimitRangeStatusEnum),
                        x => x.key,
                        x => x.value
                    )}
                    onChange={handleFilterMultiSelectChange}
                    name='statusIds'
                    title='Application Sources'
                />
                <StyledDateContainer>
                    <DateInput
                        placeholder='Date From'
                        onDateSelect={handleDateChange('dateFrom')}
                        nullable={false}
                        value={filters.dateFrom}
                        maxDate={filters.dateTo}
                    />
                    <Icon iconName='Remove' style={{ padding: '0 10px' }} />
                    <DateInput
                        placeholder='Date To'
                        onDateSelect={handleDateChange('dateTo')}
                        nullable={false}
                        value={filters.dateTo}
                        minDate={filters.dateFrom}
                    />
                </StyledDateContainer>
                <StyledRefreshContainer>
                    <CreditLimitReviewDataDownload
                        creditLimit={persistedFilters.creditLimit}
                        creditLimitRange={persistedFilters.creditLimitRange}
                        dateFrom={persistedFilters.dateFrom}
                        dateTo={persistedFilters.dateTo}
                        statusIds={persistedFilters.statusIds}
                    />
                    <IconButton iconName='Refresh' onClick={handleRefreshClick} disabled={isLoading} title='Refresh' />
                </StyledRefreshContainer>
            </StyledFilterBar>
            <StyledSection>
                <GraphQlLoading isLoading={isLoading} message='Loading'>
                    <GraphQlErrorBoundary error={error as ApolloError}>
                        {data && data.length > 0 && (
                            <VirtualTable<GetCreditLimitReportData>
                                columns={columns}
                                items={data}
                                keyFieldName='customerCompanyId'
                            />
                        )}
                    </GraphQlErrorBoundary>
                </GraphQlLoading>
            </StyledSection>
        </StyledCreditLimitReviewReportPage>
    );
};

export default CreditLimitReviewReportPage;
